export const sections = [
  {
    img: require("@/assets/images/jobs.png"),
    title: "Quantum Job Analysis",
    text: "What does the Quantum Industry really need? No one person can say for sure, and that is why the EQRC are using large-scale job market data to analyse industry trends across the globe.",
    path: "/quantum-job-analysis",
  },
  {
    img: require("@/assets/images/masters.png"),
    title: "Quantum Master Studies",
    text: "Workforce development, is at the forefront of many of nations quantum strategy. Can Master programs be a solution for this? The EQRC is analysing the landscape of tertiary quantum education to recommend the best approaches to developing quantum specialists.",
    path: "/quantum-master-studies",
  },
  {
    img: require("@/assets/images/national.png"),
    title: "Quantum National Strategies",
    text: "What are the approaches to development of a fruitful quantum industry across the world? The EQRC analyse strategic documents in order to best understand how we might develop widespread Quantum Readiness.",
    path: "/quantum-national-strategies",
  },
  {
    img: require("@/assets/images/startups.png"),
    title: "Quantum Startups",
    text: "Commericalising Quantum Technologies is a key challenge for the growth of the industry. We study the growth of quantum startups worldwide, their products, use cases, and contributions.",
  },

  {
    img: require("@/assets/images/hybrid.png"),
    title: "Quantum Hybrid Intelligence",
    text: "AI is advancing everywhere in the world. How is it being adopted in quantum research and development? What is the human/expert interface to these algorithms and how could it be improved to tap into both the power of AI and human intuition?",
  },
  {
    img: require("@/assets/images/youtube.png"),
    title: "Quantum Youtube Inventory",
    text: "The EQRC team are developing an algorithmic method to extract highest quality explanatory QT videos from Youtube and match them to Competence Framework, continuously extending the Quantum Playlists.",
  },
];
