<script>
import LSection from "@/components/layout/LSection.vue";
import { sections } from "@/data/analyseSectionData";

export default {
  name: "AnalysisSection",
  setup() {
    return { sections };
  },
  components: {
    LSection,
  },
};
</script>

<template>
  <LSection>
    <template #body>
      <div class="w-full py-20">
        <div
          class="flex flex-col rounded k1:flex-row items-center gap-8 h-full"
        >
          <div class="justify-center w-full">
            <div
              class="grid grid-cols-3 gap-12 items-center place-items-center"
            >
              <div v-for="section in sections" :key="section.title" class="">
                <img :src="section.img" alt="" class="object-contain w-28" />
              </div>
            </div>
          </div>
          <div class="w-full h-full group flex flex-col justify-between">
            <p
              style="
                background: -webkit-linear-gradient(left, #db2777, #f97316);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              "
              class="heading2 k1:!text-5xl text-2xl"
            >
              Explore our diverse quantum analysis
            </p>

            <div class="mt-0 k1:text-xl text-base leading-8">
              Within the EQRC-Analysis, we are spearheading research in the
              quantum technology industry. QT is an ever-changing landscape and
              the job market reflects that. What are the skills needed for the
              workforce of the present and the future? And how are nations of
              the world building their quantum industries?

              <hr class="gradientLine" />
              <button
                class="mt-4 button main"
                @click="() => $router.push(`/analysis`)"
              >
                <!--                Click here to know more-->
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LSection>
</template>

<style scoped></style>
