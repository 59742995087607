<template>
  <LSection>
    <template #body>
      <h1 class="heading1">
        Quantum playlist
        <hr class="gradientLine" />
      </h1>
      <section class="flex gap-4">
        <aside
          class="k1:flex k1:!sticky k1:w-[32%] k1:top-32 fixed h-full bg-slate-50 k1:z-20 z-50 p-3 flex-col top-0 !overflow-y-auto transition-all duration-100 k1:shadow-none shadow-2xl k1:translate-x-0 left-0"
          :class="{ '-translate-x-full': !isOpen }"
        >
          <InformationSection />
          <ul
            class="flex-grow list-disc top-0 sticky overflow-y-auto rounded-tr-3xl"
          >
            <li
              class="bg-slate-100/25 border border-slate-100/25 rounded-md"
              v-for="item in sidebarData"
              :key="item.id"
            >
              <div
                @click="toggleSubMenu(item)"
                :class="{ open: item.open }"
                class="border-b-2 border-titleBackground/70 flex items-center justify-between p-1 group"
              >
                <h4
                  class="cursor-pointer heading4 !font-bold !mb-0 !text-black"
                >
                  {{ getCustomTitle(item.title) }}
                </h4>

                <button v-show="!item.open">
                  <ArrowDown />
                </button>
                <button v-show="item.open">
                  <ArrowTop />
                </button>
              </div>
              <ul class="ml-2" v-if="item.children && item.open">
                <div>
                  <li v-for="child in item.children" :key="child.id">
                    <div class="flex items-center my-1">
                      <EasyCircle v-if="child.level === 'Easy'" />
                      <MediumCircle v-if="child.level === 'Medium'" />
                      <AdvancedCircle v-if="child.level === 'Advanced'" />
                      <MixedCircle v-if="child.level === 'Mixed'" />
                      <NotLevel v-if="!child.level" />
                      <router-link
                        class="font-body k1:text-base text-sm leading-none w-full hover:bg-gray-100 p-0.5"
                        :to="'/playlist/page/' + child.id"
                      >
                        {{ getCustomTitle(child.title) }}
                      </router-link>
                    </div>
                    <ul class="ml-0" v-if="child.children">
                      <li v-for="subChild in child.children" :key="subChild.id">
                        <div
                          @click="toggleSubChild(subChild)"
                          :class="{ open: subChild.open }"
                          class="border-b-2 border-[#4639FF]/50 flex items-center justify-between cursor-pointer ml-4"
                        >
                          <h3
                            class="heading4 !font-bold !text-sm !text-black !mb-0"
                          >
                            {{ subChild.title }}
                          </h3>

                          <button v-if="!subChild.open">
                            <ArrowDown />
                          </button>
                          <button v-if="subChild.open" class="">
                            <ArrowTop />
                          </button>
                        </div>
                        <ul
                          class="ml-4"
                          v-if="subChild.children && subChild.open"
                        >
                          <li v-for="deep in subChild.children" :key="deep.id">
                            <div
                              class="ml-4 flex items-center my-1 cursor-pointer"
                            >
                              <div class="levelIcon">
                                <EasyCircle
                                  v-if="
                                    deep.level === 'Easy' ||
                                    !deep.id === 29 ||
                                    !deep.id === 33 ||
                                    !deep.id === 39
                                  "
                                />
                                <MediumCircle
                                  v-if="
                                    deep.level === 'Medium' ||
                                    !deep.id === 29 ||
                                    !deep.id === 33 ||
                                    !deep.id === 39
                                  "
                                />
                                <AdvancedCircle
                                  v-if="
                                    deep.level === 'Advanced' ||
                                    !deep.id === 29 ||
                                    !deep.id === 33 ||
                                    !deep.id === 39
                                  "
                                />
                                <MixedCircle
                                  v-if="
                                    deep.level === 'Mixed' ||
                                    !deep.id === 29 ||
                                    !deep.id === 33 ||
                                    !deep.id === 39
                                  "
                                />
                                <NotLevel
                                  v-if="
                                    !deep.level &&
                                    ![29, 33, 39].includes(deep.id)
                                  "
                                />
                              </div>
                              <div
                                v-if="
                                  deep.id === 29 ||
                                  deep.id === 33 ||
                                  deep.id === 39
                                "
                                class="border-b border-b-[#7cb4d9] flex items-center justify-between w-full"
                                @click="toggleDeepChild(deep)"
                              >
                                <h4
                                  class="heading5 !text-base !font-semibold !text-black !mb-0"
                                >
                                  {{ deep.title }}
                                </h4>

                                <button v-if="!deep.open">
                                  <ArrowDown />
                                </button>
                                <button v-if="deep.open" class="">
                                  <ArrowTop />
                                </button>
                              </div>
                              <router-link
                                v-else
                                class="font-body k1:text-base text-sm w-full hover:bg-gray-100"
                                :to="'/playlist/page/' + deep.id"
                              >
                                {{ deep.title }}
                              </router-link>
                            </div>

                            <ul class="ml-4" v-if="deep.children && deep.open">
                              <li
                                v-for="depin in deep.children"
                                :key="depin.id"
                                class="flex items-center ml-2"
                              >
                                <EasyCircle v-if="depin.level === 'Easy'" />
                                <MediumCircle v-if="depin.level === 'Medium'" />
                                <AdvancedCircle
                                  v-if="depin.level === 'Advanced'"
                                />
                                <MixedCircle v-if="depin.level === 'Mixed'" />
                                <NotLevel v-if="!depin.level" />
                                <router-link
                                  class="font-body text-base w-full hover:bg-gray-100"
                                  :to="'/playlist/page/' + depin.id"
                                >
                                  {{ depin.title }}
                                </router-link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </aside>
        <div class="k1:w-[68%] w-full">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </section>
      <div
        class="fixed inset-0 !w-screen !h-screen transition-all duration-100 hidden z-10"
        :class="{ 'bg-black/50 !flex': isOpen }"
        @click="handleClickOutsideSidebar"
      ></div>
      <div
        class="fixed k1:hidden top-[100px] left-1 bg-white cursor-pointer rounded-lg border z-50"
        :class="{ hidden: isOpen }"
      >
        <burgerMenu @toggleMenu="toggleIsOpen"></burgerMenu>
      </div>
    </template>
  </LSection>
</template>

<script>
import { ref } from "vue";
import ArrowDown from "./icons/ArrowDown.vue";
import ArrowTop from "./icons/ArrowTop.vue";
import EasyCircle from "./icons/EasyCircle.vue";
import MediumCircle from "./icons/MediumCircle.vue";
import AdvancedCircle from "./icons/AdvancedCircle.vue";
import MixedCircle from "./icons/MixedCircle.vue";
import NotLevel from "./icons/NotLevel.vue"; // import data from "@/addData.json";
import { useSidebarSections } from "@/views/newPlaylist/playlist.js";
import LSection from "@/components/layout/LSection.vue";
import BurgerMenu from "@/components/BurgerMenu.vue";
import InformationSection from "@/views/newPlaylist/InformationSection.vue";

export default {
  setup() {
    const { sideBarSection } = useSidebarSections();
    const sidebarDataRef = ref(sideBarSection);
    const isOpen = ref(false);

    // console.log(sideBarSection);

    const getCustomTitle = (originalTitle) => {
      if (
        originalTitle ===
        "Introduction to quantum physics/Concepts and foundations"
      ) {
        return "Introduction to Quantum";
      } else if (originalTitle === "Solid state and quantum technologies") {
        return "Solid state and quantum";
      } else if (originalTitle === "Gate based quantum computing") {
        return "Gate based quantum";
      } else if (originalTitle === "Quantum Sensing & Metrology") {
        return "Quantum Sensing";
      } else if (originalTitle === "Enabling technologies/concept") {
        return "Enabling technologies";
      } else {
        return originalTitle;
      }
    };

    const handleClickOutsideSidebar = () => {
      isOpen.value = false;
    };
    const toggleSubMenu = (item) => {
      item.open = !item.open;
    };

    const toggleIsOpen = () => {
      isOpen.value = !isOpen.value;
    };

    const toggleSubChild = (child) => {
      child.open = !child.open;
    };

    const toggleDeepChild = (deep) => {
      deep.open = !deep.open;
    };

    return {
      sidebarData: sidebarDataRef,
      getCustomTitle,
      toggleSubMenu,
      toggleSubChild,
      toggleDeepChild,
      toggleIsOpen,
      isOpen,
      handleClickOutsideSidebar,
    };
  },
  components: {
    InformationSection,
    BurgerMenu,
    LSection,
    ArrowDown,
    ArrowTop,
    EasyCircle,
    MediumCircle,
    AdvancedCircle,
    MixedCircle,
    NotLevel,
  },
};
</script>

<style scoped>
a.router-link-exact-active {
  color: #f82553;
  background-color: rgba(209, 232, 255, 0.3);
}
::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 6px; /* roundness of the scroll thumb */
  border: 3px solid #f0f0f0; /* creates padding around scroll thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f0f0f0; /* thumb and track color */
}
</style>
