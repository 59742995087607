<template>
  <div class="dropdown relative">
    <button
      :class="['button', 'dropdown', buttonClass, disable ? 'opacity-70' : '']"
      type="button"
      :key="dropdown"
      @click.stop="toggle($event)"
    >
      {{ title || "Select an item" }}
      <svg
        class="ml-2 w-4 h-4"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>
    <!-- Dropdown menu -->
    <div
      v-show="show"
      class="z-10 w-fit absolute bottom-0 translate-y-full bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
    >
      <div v-if="showSearch" class="px-4 py-2">
        <input
          type="text"
          v-model="search"
          class="w-full py-1 px-2 text-sm text-gray-700 rounded dark:text-gray-200"
          placeholder="Search items..."
          ref="searchInput"
          @keydown.down.prevent="focusNext"
          @keydown.up.prevent="focusPrev"
          @keydown.enter.prevent="selectFocused"
        />
      </div>
      <ul
        class="py-1 text-sm text-gray-700 dark:text-gray-200 max-h-48 w-full overflow-y-scroll overscroll-none"
      >
        <li
          v-for="(item, index) in filteredItems"
          :key="item"
          @click.stop="onClick"
        >
          <div
            :id="item"
            :class="[
              'block py-2 px-4 ',
              index === focusedIndex ? 'bg-gray-200 dark:text-gray-700' : '',
              'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
            ]"
            @mouseover="focusedIndex = index"
          >
            {{ item }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { isString } from "@/lib/getVariableType";
import dropdown from "@/components/forms/Dropdown.vue";

export default {
  name: "Dropdown",
  computed: {
    dropdown() {
      return dropdown;
    },
  },
  emits: ["select"],
  props: {
    buttonClass: {
      type: String,
    },
    title: {
      type: String,
    },
    items: {
      // first item is the 'all' option
      type: Array,
      required: true,
      validator: (v) =>
        Array.isArray(v) &&
        v.reduce((pass, item) => pass && isString(item), true),
    },
    disable: {
      type: Boolean,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(false);
    const search = ref("");
    const onClick = (e) => {
      e.preventDefault(); // do not bubble to button
      show.value = false;
      emit("select", e.target.id);
    };
    const closeDropdown = () => {
      show.value = false;
    };
    const onClickOutside = (event) => {
      const dropdownElement = event.target.closest(".dropdown.relative");

      if (!dropdownElement) {
        closeDropdown();
      }
    };
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });
    const toggle = () => {
      show.value = !show.value;
    };
    const filteredItems = computed(() =>
      props.items.filter((item) =>
        item.toLowerCase().includes(search.value.toLowerCase())
      )
    );
    const focusedIndex = ref(-1);
    const focusNext = () => {
      focusedIndex.value =
        focusedIndex.value === filteredItems.value.length - 1
          ? 0
          : focusedIndex.value + 1;
    };
    const focusPrev = () => {
      focusedIndex.value =
        focusedIndex.value === 0
          ? filteredItems.value.length - 1
          : focusedIndex.value - 1;
    };
    const selectFocused = () => {
      if (focusedIndex.value !== -1) {
        show.value = false;
        emit("select", filteredItems.value[focusedIndex.value]);
      }
    };
    return {
      onClick,
      show,
      toggle,
      search,
      filteredItems,
      focusedIndex,
      focusNext,
      focusPrev,
      selectFocused,
    };
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 6px; /* roundness of the scroll thumb */
  border: 2px solid #f0f0f0; /* creates padding around scroll thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f0f0f0; /* thumb and track color */
}
</style>
