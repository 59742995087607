<template>
  <div
    class="w-full lg:w-fit px-2 p-1 items-center rounded-tl-3xl rounded-br-3xl flex flex-col text-gray-700"
  >
    <div class="flex flex-wrap items-start k1:gap-2 gap-3 text-sm">
      <p class="flex items-center font-medium">
        <EasyCircle />
        Easy
      </p>
      <div class="flex items-center font-medium">
        <MediumCircle />
        Medium
      </div>
      <p class="flex items-center font-medium">
        <AdvancedCircle />
        Advanced
      </p>
      <p class="flex items-center font-medium">
        <MixedCircle />
        Mixed
      </p>
    </div>
  </div>
</template>

<script>
import AdvancedCircle from "@/views/newPlaylist/icons/AdvancedCircle.vue";
import EasyCircle from "@/views/newPlaylist/icons/EasyCircle.vue";
import MediumCircle from "@/views/newPlaylist/icons/MediumCircle.vue";
import MixedCircle from "@/views/newPlaylist/icons/MixedCircle.vue";

export default {
  components: { EasyCircle, MediumCircle, AdvancedCircle, MixedCircle },
};
</script>

<style lang="scss" scoped></style>
